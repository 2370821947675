import { HubConnection } from "@microsoft/signalr";
import React, { ReactElement } from "react";
import { Button, Form } from "react-bootstrap";
import { Text } from "../Types/textTypes";

interface Props {
  addVideo: (e: any) => any;
  message: string;
  socketId: string | undefined;
  hub: HubConnection | undefined;
  text: Text[];
  addText: (e: any) => void;
  removeText: () => void;
  ulChat: any;
}

export default function AddVideoForm({
  addVideo,
  message,
  socketId,
  hub,
  text,
  addText,
  removeText,
  ulChat,
}: Props): ReactElement {
  return (
    <div>
      <Form onSubmit={addVideo}>
        <Form.Group className="mb-3" controlId="formUsername">
          <Form.Label>Uploader </Form.Label>
          <Form.Control type="input" placeholder="Enter uploader name" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formUrl">
          <Form.Label>Link</Form.Label>
          <Form.Control type="input" placeholder="Paste Link" />
        </Form.Group>
        <div
          style={{
            minHeight: "30px",
          }}
        >
          {message}
        </div>
        <br />
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>

      {socketId && hub ? (
        <div>
          <br />
          <br />
          <br />
          <Button variant="danger" className="mb-3" onClick={removeText}>
            Clear Chat
          </Button>
          <div
            style={{
              height: "250px",
              maxWidth: "985px",
              border: "1px solid black",
              marginBottom: "20px",
              overflowX: "hidden",
              overflowY: "auto",
              textAlign: "justify",
            }}
            ref={ulChat}
          >
            {text.map((info, index) => {
              const str = info.from.split(".");
              const ip = `${str[0]}.${str[1]}.${str[2]}.XXX`;
              return (
                <h5 key={index} style={{ fontSize: "15px" }}>
                  {info.createdTime}
                  <br />
                  <span style={{ color: "red" }}>{ip}: </span>
                  {info.text}
                </h5>
              );
            })}
          </div>
          <Form inline onSubmit={addText}>
            <Form.Group className="mb-3" controlId="message">
              <Form.Control type="input" placeholder="Enter Message" />
            </Form.Group>
            <Button variant="primary" type="submit" className="mb-3">
              Send
            </Button>
          </Form>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
// namn, länk
