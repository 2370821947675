import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import VideoPlayer from "../components/videoPlayer";
import { Content } from "../Types/contentTypes";

interface Props {
  contents: Content[] | undefined;
  removeVideo: (id: string) => void;
  upVote: (id: string, vote: number) => void;
}

export default function ContentPage({
  contents,
  removeVideo,
  upVote,
}: Props): ReactElement {
  if (contents === undefined) return <div>"Loading..."</div>;
  return (
    <Row>
      {contents.map((data: Content, index) => {
        return (
          <Col xs={12} md={6} xl={4} key={data.id}>
            <VideoPlayer
              data={data}
              removeVideo={removeVideo}
              upVote={upVote}
            />
          </Col>
        );
      })}
    </Row>
  );
}
