import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSkull,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { ReactElement } from "react";
import ReactPlayer from "react-player";
import { Content } from "../Types/contentTypes";
import "./videoPlayer.css";
import { FaCopy } from "react-icons/fa";

interface Props {
  data: Content;
  removeVideo: (id: string) => void;
  upVote: (id: string, vote: number) => void;
}

export default function VideoPlayer({
  data,
  removeVideo,
  upVote,
}: Props): ReactElement {
  function copyToClipboard(): void {
    const textarea = document.createElement('textarea');
    textarea.value = data.link;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  return (
    <div className="grid-container">
      <div className="leftPanel">
        <div className="icons">
          <FontAwesomeIcon
            icon={faThumbsUp}
            className="iconsText up"
            onClick={() => upVote(data.id, 1)}
          />
          <span
            className="badgeText"
            style={{ color: data.upvote >= 0 ? "blue" : "red" }}
          >
            {data.upvote}
          </span>
        </div>

        <div className="icons">
          <FontAwesomeIcon
            icon={faThumbsDown}
            className="iconsText down"
            onClick={() => upVote(data.id, -1)}
          />
          {/* <span className="badgeText down">{data.downvote}</span> */}
        </div>

        <div className="icons">
          <FontAwesomeIcon
            className="iconsText skull"
            icon={faSkull}
            onClick={() => removeVideo(data.id)}
          />
        </div>
      </div>

      <div className="topCenter">
        <span className="left">By: {data.username}</span>
        <span className="right" >
          <FaCopy 
            onClick={copyToClipboard} 
            className="icon" 
          /> 
        </span>
        
      </div>
      <div className="middleCenter">
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={data.link}
            width="100%"
            height="100%"
            controls={true}
            playing={false}
            loop={true}
          />
        </div>
        <span style={{ textAlign: "left" }}>
          {data.createdDay} {data.createdTime}
        </span>
      </div>
      <div className="bottemCenter"></div>
    </div>
  );
}