export type Content = {
  id: string;
  username: string;
  link: string;
  createdDay: string;
  createdTime: string;
  upvote: number;
  downvote: number;
};

// /**
//  * Run `npm run generate` to create this type guard.
//  * @see {isContents} ts-auto-guard:type-guard
//  */
export function isContents(obj: any, _argumentName?: string): obj is Content[] {
  return (
    Array.isArray(obj) &&
    obj.every(
      (e: any) =>
        ((e !== null && typeof e === "object") || typeof e === "function") &&
        typeof e.id === "string" &&
        typeof e.username === "string" &&
        typeof e.link === "string" &&
        typeof e.createdDay === "string" &&
        typeof e.createdTime === "string" &&
        typeof e.upvote === "number" &&
        typeof e.downvote === "number"
    )
  );
}
