import React, { useState, useEffect, useRef } from "react";
import ContentPage from "./Pages/ContentPage";
import { Content, isContents } from "./Types/contentTypes";
import { Col, Container, Row } from "react-bootstrap";
import AddVideoForm from "./components/addVideoForm";
import ReactPlayer from "react-player";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Text } from "./Types/textTypes";

//const server = "http://hejaswe.duckdns.org/";
const server = "http://yeofeja.duckdns.org/";
//const server = "http://192.168.1.101:5000/";
// const server = "http://192.168.1.100:5000/";
// const server = "http://localhost/"

function App() {
  const [content, setContent] = useState<[] | Content[]>([]);
  const [message, setMessage] = useState<string>("");
  const [start, setStart] = useState<Boolean>(true);
  const [hub, sethub] = useState<HubConnection | undefined>();
  const [socketId, setSocketId] = useState<string | undefined>();
  const [text, setText] = useState<[] | Text[]>([]);
  const ulChat = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (text.length > 1 && ulChat.current) {
      if (
        text[text.length - 1].from === socketId ||
        ulChat.current.scrollTop + 350 > ulChat.current.scrollHeight ||
        start === true
      ) {
        ulChat.current.scrollTop = ulChat.current.scrollHeight;
        setStart(false);
      }
    }
  }, [text, socketId, start]);

  useEffect(() => {
    try {
      (async function () {
        const response = await fetch(server + "api/videos/");
        const data = await response.json();
        if (!isContents(data)) {
          throw new Error("GG");
        }
        const response2 = await fetch(server + "api/messages/");
        const data2 = await response2.json();

        const connection = new HubConnectionBuilder()
          .withUrl(server + "videohub")
          .build();
        connection.on("voteup", (id: string, vote: number) => {
          setContent((prevState) =>
            prevState.map((data) => {
              if (data.id === id) {
                data.upvote = vote;
              }
              return data;
            })
          );
        });
        connection.on("connectedUserId", (id) => setSocketId(id));
        connection.on("message", (newText) => {
          setText((prevState) => [...prevState, newText]);
        });
        connection.on("removevideo", (id) => {
          setContent((prevState) => prevState.filter((data) => data.id !== id));
        });
        connection.on("addvideo", (data) => {
          setContent((prevState) => [data, ...prevState!]);
        });
        connection.on("removeText", (data) => {
          setText([]);
        });
        sethub(connection);
        setContent(data);
        setText(data2);

        connection.start();
      })();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const addVideo = async (e: any) => {
    e.preventDefault();
    if (!ReactPlayer.canPlay(e.target.elements.formUrl.value))
      return setMessage("Url is not a valid video");
    if (!e.target.elements.formUsername.value)
      return setMessage("Please enter a valid username");
    const response = await fetch(server + "api/videos/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: e.target.elements.formUsername.value,
        link: e.target.elements.formUrl.value,
      }),
    });

    const data = await response.json();

    if (response.status === 201) {
      // setContent((prevState) => [data, ...prevState!]);
      hub!.invoke("SendVideo", data).catch(function (err) {
        return console.error(err.toString());
      });
      setMessage("Video Added");
      e.target.elements.formUsername.value = "";
      e.target.elements.formUrl.value = "";
    } else {
      setMessage("Video cannot be added check the fields");
    }
  };

  const addText = async (e: any) => {
    e.preventDefault();
    const response = await fetch(server + "api/messages/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: socketId,
        to: null,
        text: e.target.elements.message.value,
      }),
    });

    const data = await response.json();
    if (response.status === 201) {
      hub!.invoke("SendMessageAsync", data).catch(function (err) {
        return console.error(err.toString());
      });
    }
    e.target.elements.message.value = "";
  };

  const removeVideo = async (id: string) => {
    if (id) {
      await fetch(server + `api/videos/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      hub!.invoke("DeleteVideo", id).catch(function (err) {
        return console.error(err.toString());
      });
      // setContent((prevState) => prevState.filter((data) => data.id !== id));
    }
  };

  const removeText = async () => {
    await fetch(server + `api/messages/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    hub!.invoke("RemoveText").catch(function (err) {
      return console.error(err.toString());
    });
  };

  const upVote = async (id: string, vote: number) => {
    const video = content.find((video) => video.id === id);

    if (video) {
      await fetch(server + `api/videos/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            value: video.upvote + vote,
            path: "/upvote",
            op: "replace",
          },
        ]),
      });
      hub!.invoke("UpVote", id, video.upvote + vote).catch(function (err) {
        return console.error(err.toString());
      });
      // setContent((prevState) => prevState.filter((data) => data.id !== id));
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs="12" lg="2">
          <AddVideoForm
            addVideo={addVideo}
            message={message}
            socketId={socketId}
            hub={hub}
            text={text}
            addText={addText}
            removeText={removeText}
            ulChat={ulChat}
          />
        </Col>
        <Col xs="12" lg="10">
          <ContentPage
            contents={content}
            removeVideo={removeVideo}
            upVote={upVote}
          />
        </Col>
        <Col lg="2" className="d-none d-lg-none"></Col>
      </Row>
    </Container>
  );
}

export default App;
